import React, { useState } from 'react';
import { toast } from 'sonner';
import { useGlobalLoader } from '../../../Store';
import { ProjectViewApi } from '../../Api';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { projectType } from '../../../Types';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ChatBox from '../../../Components/ChatBox';

export default function FreelancerProjectView() {
    const { id } = useParams();
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
    const navigate = useNavigate();
    const [projectData, setProjectData] = useState<projectType>();

    useQuery({
        queryKey: ['project-view', id],
        queryFn: ProjectViewApi,
        onSuccess: (resp) => {
            setProjectData(resp?.data?.project);
            setIsLoading(false);
        },
        onError: (err: any) => {
            setIsLoading(false);
            toast.error(err.response.data.message);
        }
    });

    return (
        <div className="content-wrapper">

            {/* Back & Heading */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col">
                            <h1>View Project</h1>
                        </div>
                        <div className="col">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item">
                                    <button className="btn btn-info text-white" onClick={() => navigate(-1)}>
                                        <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                                        Back
                                    </button>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            {/* Main Content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <Tabs defaultActiveKey="Details" id="justify-tab-example" className="mb-3 tabs" justify>
                                        <Tab eventKey="Details" title="Details">
                                            <div className="table-responsive mt-4">
                                                <div className="card-body">
                                                    <table id="example2" className="table table-bordered">
                                                        {projectData ?
                                                            <tbody>
                                                                <tr>
                                                                    <td className="col-sm-2 font-weight-bold">Project Name</td>
                                                                    <td>{projectData?.project_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="col-sm-2 font-weight-bold">Description</td>
                                                                    <td>{projectData?.description}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="col-sm-2 font-weight-bold">Status</td>
                                                                    <td>{projectData?.status === 1 && "Active"}{projectData?.status === 0 && "Deactive"}{projectData?.status === 2 && "Completed"}</td>
                                                                </tr>
                                                            </tbody>
                                                            : null
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Chat" title="Chat">
                                            <ChatBox project_id={Number(id)} chatType='freelancer' takeScreenShot={true} />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}
