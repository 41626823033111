import { changePassword, clientType, loginType, projectType } from "../../Types";
import HttpService from "./interceptors";

export const LoginAPI = async (values: loginType) => await HttpService.post("/admin/login", values);
export const ChangePasswordApi = async (values: changePassword) => await HttpService.post("/admin/change/password", values);
export const AdminDashboardApi = async () => await HttpService.get("/admin/dashboard");


//Client API
export const ClientListApi = async ({ queryKey }: any) => await HttpService.get(`/admin/client/list?search=${queryKey[1]}&page=${queryKey[2]}`);
export const ClientDeleteApi = async (values: number) => await HttpService.delete(`/admin/client/delete/${values}`);
export const ClientAddApi = async (values: clientType) => await HttpService.post('/admin/client/add', values);
export const ClientEditApi = async (values: clientType) => await HttpService.put(`/admin/client/update/${values?.id}`, {
    name: values.name,
    email: values.email,
    status: values.status,
});
export const ClientViewApi = async ({ queryKey }: any) => await HttpService.get(`/admin/client/view/${queryKey[1]}`);


//Freelancer API
export const FreelancerListApi = async ({ queryKey }: any) => await HttpService.get(`/admin/freelancer/list?search=${queryKey[1]}&page=${queryKey[2]}`);
export const FreelancerDeleteApi = async (values: number) => await HttpService.delete(`/admin/freelancer/delete/${values}`);
export const FreelancerAddApi = async (values: clientType) => await HttpService.post('/admin/freelancer/add', values);
export const FreelancerEditApi = async (values: clientType) => await HttpService.put(`/admin/freelancer/update/${values?.id}`, {
    name: values.name,
    email: values.email,
    status: values.status,
});
export const FreelancerViewApi = async ({ queryKey }: any) => await HttpService.get(`/admin/freelancer/view/${queryKey[1]}`);


//Project API
export const ProjectListApi = async ({ queryKey }: any) => await HttpService.get(`/admin/project/list?search=${queryKey[1]}&page=${queryKey[2]}`);
export const ProjectAddApi = async (values: projectType) => await HttpService.post('/admin/project/add', values);
export const ProjectDeleteApi = async (values: number) => await HttpService.delete(`/admin/project/delete/${values}`);
export const ProjectEditApi = async (values: projectType) => await HttpService.put(`/admin/project/update/${values?.id}`, {
    project_name: values.project_name,
    description: values.description,
    client_id: values.client_id,
    freelancer_id: values.freelancer_id,
    status: values.status
});
export const ProjectViewApi = async ({ queryKey }: any) => await HttpService.get(`/admin/project/view/${queryKey[1]}`);
export const ViewProjectScreenshot = async({ queryKey }: any) => await HttpService.get(`/admin/project/screenshot/${queryKey[1]}?limit=${queryKey[2]}`);