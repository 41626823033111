import React, { useState } from "react";
import { useGlobalLoader } from "../../../Store";
import { useMutation, useQuery } from "react-query";
import { FreelancerDeleteApi, FreelancerListApi } from "../../Api";
import { freelancerType } from "../../../Types";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { AiFillCheckCircle, AiFillCloseCircle, AiFillEye } from "react-icons/ai";
import { MdDeleteForever, MdEdit } from "react-icons/md";
import CustomModal from "../../../Components/Modal";

export default function Freelancer() {

  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [freelancer, setFreelancer] = useState<{ totalPage: number, freelancer: freelancerType[] }>();
  const navigate = useNavigate();

  const { refetch, isFetching } = useQuery({
    queryKey: ['freelancer-list', search, page],
    queryFn: FreelancerListApi,
    onSuccess: (resp) => {
      setFreelancer(resp.data);
      setIsLoading(false);
      if (resp.data.totalPage < page) {
        setPage(1);
      }
    },
    onError: (err: any) => {
      toast.error(err.response.data.message);
      setIsLoading(false);
    }
  });

  const { mutate } = useMutation(FreelancerDeleteApi, {
    mutationKey: "freelancer-delete",
    onSuccess: (data: any) => {
      toast.success(data.data.message);
      refetch();
      setIsLoading(false);
      setshowDeleteModal(false);
    },
    onError: (data: any) => {
      toast.error(data.response.data.message);
      setshowDeleteModal(false);
      setIsLoading(false);
    },
  });

  return (
    <div className="content-wrapper">

      {/* Delete Modal */}
      <CustomModal show={showDeleteModal}
        setShow={setshowDeleteModal}
        handleSuccess={() => {
          if (deleteId) {
            mutate(deleteId);
            setIsLoading(true);
          }
        }}
        successButton="Delete"
        description="Are you sure you want to delete?"
        header="Freelancer Delete"
        type="danger"
      />

      {/* Add Freelancer & Heading */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col">
              <h1>Freelancers</h1>
            </div>
            <div className="col">
              <ol className="breadcrumb float-right">
                <li className="breadcrumb-item">
                  <button className="btn btn-primary" onClick={() => navigate('/admin/freelancer/add')}>
                    <i className="fa fa-plus mx-1" aria-hidden="true"></i>
                    Add Freelancer
                  </button>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-end ">
                    <div className="d-flex my-2 mx-1">
                      <input
                        placeholder="Search here..."
                        type="text"
                        className="form-control form-control-md"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div className="card-body">
                    <table
                      id="example2"
                      className="table table-bordered table-hover"
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "100px" }}>Sr no.</th>
                          <th style={{ minWidth: "150px" }}>Name</th>
                          <th style={{ minWidth: "150px" }}>E-mail</th>
                          <th className="text-center" style={{ width: "100px" }}>Status</th>
                          <th className="text-center" style={{ width: "200px" }}>Action</th>
                        </tr>
                      </thead>
                      {freelancer?.freelancer ? (
                        <tbody>
                          {freelancer?.freelancer[0] ? (
                            freelancer?.freelancer.map((item, i) => (
                              <tr key={i}>
                                <td>{i + 1 + (page - 1) * 10}</td>
                                <td className="p-1">{item?.name}</td>
                                <td className="p-1">{item?.email}</td>
                                <td className="p-1">
                                  <div className="d-flex justify-content-center ">
                                    {item.status === 1 ? (
                                      <AiFillCheckCircle className="text-success h2" />
                                    ) : (
                                      <AiFillCloseCircle className="text-danger h2" />
                                    )}
                                  </div>
                                </td>
                                <td className="p-1">
                                  <div className="d-flex flex-row justify-content-center" style={{ margin: "0px" }} >
                                    {/* View */}
                                    <AiFillEye className="text-info mx-2 cursor-pointer-h h2" onClick={() => navigate(`/admin/freelancer/view/${item.id}`)} />
                                    {/* Edit */}
                                    <MdEdit className="text-primary mx-2 cursor-pointer-h h2" onClick={() => navigate(`/admin/freelancer/edit/${item.id}`)} />
                                    {/* Delete */}
                                    <MdDeleteForever className="text-danger mx-2 cursor-pointer-h h2" onClick={() => { setshowDeleteModal(true); setDeleteId(item.id!) }} />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6}>
                                <div className="d-flex justify-content-center">
                                  <p style={{ fontSize: "30px", padding: "0px", margin: "0px" }}>No Data Found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      ) : null}
                    </table>
                    {isFetching ? (
                      <center><i className=" my-2 fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "black" }}></i></center>
                    ) : null}
                  </div>
                </div>

                {freelancer?.totalPage ? (
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={freelancer?.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    forcePage={page - 1}
                    onPageChange={(pageClicked) => {
                      setPage(pageClicked.selected + 1);
                    }}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                ) : null}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
        </div>
        {/* /.container-fluid */}
      </section>

    </div>
  );
}
