import * as Yup from "yup";

export const LoginSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("E-mail is required"),
    password: Yup.string().required("Password is required"),
});

export const ChangePasswordSchema = Yup.object({
    old_password: Yup.string().required("Old password is required"),
    new_password: Yup.string().required("New password is required"),
    confirm_password: Yup.string().oneOf([Yup.ref("new_password")], "Password does not match").required("Confirm password is required"),
});

export const ClientAddSchema = Yup.object({
    name: Yup.string().max(255, "Name must not be greater than 255 characters.").required('Name is required'),
    email: Yup.string().max(255, "Email must not be greater than 255 characters.").matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/, 'Email is not valid.').required("Email field is required."),
    status: Yup.number().required("Status is required"),
    password: Yup.string().max(255, "Password must not be greater than 255 characters.").min(7, "Password must contain at least 7 characters").required("Password field is required."),
    confirmPassword: Yup.string().oneOf([Yup.ref("password"), ""], "Password And Confirm Password Does Not Match.").required("Confirm Password field is required."),
});

export const ClientEditSchema = Yup.object({
    name: Yup.string().max(255, "Name must not be greater than 255 characters.").required('Name is required'),
    email: Yup.string().max(255, "Email must not be greater than 255 characters.").matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/, 'Email is not valid.').required("Email field is required."),
    status: Yup.number().required("Status is required"),
});

export const FreelancerAddSchema = Yup.object({
    name: Yup.string().max(255, "Name must not be greater than 255 characters.").required('Name is required'),
    email: Yup.string().max(255, "Email must not be greater than 255 characters.").matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/, 'Email is not valid.').required("Email field is required."),
    password: Yup.string().max(255, "Password must not be greater than 255 characters.").min(7, "Password must contain at least 7 characters").required("Password field is required."),
    confirmPassword: Yup.string().oneOf([Yup.ref("password"), ""], "Password And Confirm Password Does Not Match.").required("Confirm Password field is required."),
});

export const FreelancerEditSchema = Yup.object({
    name: Yup.string().max(255, "Name must not be greater than 255 characters.").required('Name is required'),
    email: Yup.string().max(255, "Email must not be greater than 255 characters.").matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/, 'Email is not valid.').required("Email field is required."),
    status: Yup.number().required("Status is required"),
});

export const ProjectAddSchema = Yup.object({
    project_name: Yup.string().max(255, "Project Name must not be greater than 255 characters.").required('Project Name is required'),
    description: Yup.string().max(255, "Project Description must not be greater than 255 characters.").required('Project Description is required'),
    status: Yup.number().required("Status is required"),
    client_id: Yup.number().required("Client is required"),
    freelancer_id: Yup.number().required("Freelancer is required"),
});