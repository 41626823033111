import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export default function AdminProtectedRoute() {
    const navigate = useNavigate();
    const [checkLoggedIn, setCheckLoggedIn] = useState(false);

    const checkToken = () => {
        const token = localStorage.getItem('admin_token');
        if(!token || token === 'undefined'){
            localStorage.removeItem('admin_token');
            setCheckLoggedIn(false);
            return navigate('/admin/login');
        }else setCheckLoggedIn(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(()=>checkToken(),[]);
    return (
        <>
            {
                checkLoggedIn ? <Outlet /> : null
            }

        </>
    );
}
