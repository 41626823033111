import AdminProjects from '../Admin/Pages/Projects/AdminProjects';
import AdminChangePassword from '../Admin/Pages/ChangePassword';
import Client from '../Admin/Pages/Client/Client';
import ClientAdd from '../Admin/Pages/Client/ClientAdd';
import ClientEdit from '../Admin/Pages/Client/ClientEdit';
import ClientView from '../Admin/Pages/Client/ClientView';
import AdminDashboard from '../Admin/Pages/Dashboard';
import Freelancer from '../Admin/Pages/Frrelancer/Freelancer';
import FreelancerAdd from '../Admin/Pages/Frrelancer/FreelancerAdd';
import FreelancerEdit from '../Admin/Pages/Frrelancer/FreelancerEdit';
import FreelancerView from '../Admin/Pages/Frrelancer/FreelancerView';
import ProjectEdit from '../Admin/Pages/Projects/ProjectEdit';
import ProjectAdd from '../Admin/Pages/Projects/ProjectAdd';
import AdminProjectView from '../Admin/Pages/Projects/AdminProjectView';

const AdminRoute = [
    { path: "dashboard", element: <AdminDashboard /> },
    { path: "change-password", element: <AdminChangePassword /> },
    
    //Project
    { path: "/admin/project", element: <AdminProjects /> },
    { path: "/admin/project/add", element: <ProjectAdd /> },
    { path: "/admin/project/edit/:id", element: <ProjectEdit /> },
    { path: "/admin/project/view/:id", element: <AdminProjectView/>},

    // Clients
    { path: "/admin/client", element: <Client /> },
    { path: "/admin/client/add", element: <ClientAdd /> },
    { path: "/admin/client/view/:id", element: <ClientView /> },
    { path: "/admin/client/edit/:id", element: <ClientEdit /> },
    
    // Freelancer
    { path: "/admin/freelancer", element: <Freelancer /> },
    { path: "/admin/freelancer/add", element: <FreelancerAdd /> },
    { path: "/admin/freelancer/view/:id", element: <FreelancerView /> },
    { path: "/admin/freelancer/edit/:id", element: <FreelancerEdit /> },


];

export default AdminRoute;