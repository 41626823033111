import React, { useEffect, useState } from 'react';
import { IoSend } from "react-icons/io5";
import { FormateDate } from '../Helper';
import TakeScreenshot from './TakeScreenshot';
import io from "socket.io-client";
import $ from 'jquery';

interface propsType {
    takeScreenShot?: boolean;
    chatType: "admin" | "freelancer" | "client",
    project_id: number
}

const getToken = (type: "admin" | "freelancer" | "client") => {

    if (type === "admin") return localStorage.getItem('admin_token');
    if (type === "freelancer") return localStorage.getItem('freelancer_token');
    if (type === "client") return localStorage.getItem('client_token');

}
export default function ChatBox({ takeScreenShot, chatType, project_id }: propsType) {
    const [chat, setChat] = useState<{ message: string, sender_id: number, sender_type: string, created_at: string }[]>([]);
    const [message, setMessage] = useState("");
    const token = getToken(chatType);
    const chatBox = document.getElementById('chat-box');

    function scrollToBottom() {
        setTimeout(() => {
            $('#chat-box').scrollTop($('#chat-box')[0].scrollHeight);
        }, 1000);
    }

    const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
        extraHeaders: {
            token: `${token}`
        }
    });

    const handleSend = () => {
        if (!message) return;
        socket.emit("message", { type: chatType, project_id: project_id, message });
        setMessage("")
    }
    useEffect(() => {
        socket.emit("getChat", { type: chatType, project_id: project_id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    socket.on("getChat", (data) => {
        if (project_id === data?.project_id) setChat(data?.chat);
    });
    socket.on("message", (data) => {
        if (project_id === data?.project_id) setChat([...chat, ...[data?.chat]]);
    });


    useEffect(() => {
        scrollToBottom();
        if (chatBox) chatBox.scrollTop = chatBox.scrollHeight;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chat, chatBox]);

    return (
        <div className='container w-100' style={{ height: "calc(100vh - 310px)" }}>
            <div className='w-100 h-100' style={{ borderRadius: '16px', overflow: 'hidden', position: 'relative' }}>

                <div className='d-flex align-items-center bg-light w-100' style={{ position: 'absolute', zIndex: 999, borderBottom: '2px solid black' }}>
                    <div className='d-flex align-items-center p-2 mx-4' >
                        <div className='profile-box bg-primary'>
                            <span className='h5 mb-0'>PN</span>
                        </div>
                        <div className='mx-2'>
                            <p className='mb-0 h6 text-dark'>Project Name</p>
                        </div>
                    </div>
                    <div className='flex-grow-1' />
                    {takeScreenShot ? <TakeScreenshot /> : null}
                </div>

                <div id='chat-box' className='chat-box bg-light h-100'>
                    {
                        chat ? chat.map((item, i) => (
                            <div className={item?.sender_type === chatType ? "outgoing d-flex justify-content-end align-items-end" : "incoming"} key={i}>

                                {item?.sender_type !== chat[i - 1]?.sender_type ?
                                    <div className='d-flex align-items-center px-2 mx-1'>
                                        <div className='mx-2 d-flex justify-content-center align-items-start' style={{ height: '25px' }}>
                                            <p className="date mb-0 chat-name">{FormateDate(item?.created_at)}</p>
                                        </div>
                                    </div>
                                    : null}
                                <div className='text bg-color-6' >
                                    <p style={{ wordWrap: 'break-word', fontWeight: '500' }} className='my-1'>{item?.message}</p>
                                </div>
                            </div>
                        )) : null
                    }
                </div>

                {/* input box */}
                <div className='w-100 d-flex send-message-box bg-light'>
                    <div className='mx-2 p-2 bg-color-3 flex-grow-1 cursor-pointer' style={{ borderRadius: "12px" }}>
                        <input onKeyDown={(e) => {
                            if (e.key === 'Enter') handleSend();
                        }} value={message} onChange={(e) => setMessage(e.target.value)} className='custom-chat-input w-100 h-100  bg-color-3 font-weight-bold' placeholder='Type a message' />
                    </div>
                    <div onClick={() => handleSend()} className='mx-2 p-2 bg-success d-flex align-items-center justify-content-center cursor-pointer' style={{ borderRadius: "50%", height: '50px', width: '50px' }}>
                        <IoSend className='h4 mb-0 text-white cursor-pointer-h' />
                    </div>
                </div>
            </div>
        </div>
    );
}
