import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { freelancerType } from '../../../Types';
import { useQuery } from 'react-query';
import { FreelancerViewApi } from '../../Api';
import { useGlobalLoader } from '../../../Store';
import { toast } from 'sonner';

export default function FreelancerView() {
  const navigate = useNavigate();
  const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
  const [freelancerData, setFreelancerData] = useState<freelancerType>();
  const { id } = useParams();

  const { refetch } = useQuery({
    queryKey: ['freelancer-view', id],
    queryFn: FreelancerViewApi,
    onSuccess: (resp) => {
      setFreelancerData(resp.data.freelancer);
      setIsLoading(false);
    },
    onError: (err: any) => {
      toast.error(err.response.data.message);
      setIsLoading(false);
    },
    enabled: false
  });

  useEffect(() => {
    setIsLoading(true);
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="content-wrapper">

      {/* Back & Heading */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col">
              <h1>View Freelancer</h1>
            </div>
            <div className="col">
              <ol className="breadcrumb float-right">
                <li className="breadcrumb-item">
                  <button className="btn btn-info text-white" onClick={() => navigate(-1)}>
                    <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                    Back
                  </button>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="table-responsive">
                  <div className="card-body">
                    <table id="example2" className="table table-bordered">
                      {
                        freelancerData ?
                          <tbody>
                            <tr>
                              <td className="col-sm-2 font-weight-bold">Name</td>
                              <td>{freelancerData?.name}</td>
                            </tr>
                            <tr>
                              <td className="col-sm-2 font-weight-bold">E-mail</td>
                              <td>{freelancerData?.email}</td>
                            </tr>
                            <tr>
                              <td className="col-sm-2 font-weight-bold">Status</td>
                              <td>{freelancerData?.status === 1 ? "Active" : "Deactive"}</td>
                            </tr>
                          </tbody>
                          : null
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
