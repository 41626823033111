import React, { useState } from "react";
import { useQuery } from "react-query";
import { FreelancerDashboardApi } from "../Api";
import { dashboardDataType } from "../../Types";
import { FaProjectDiagram } from "react-icons/fa";
import { GrCompliance } from "react-icons/gr";

function FreelancerDashboard() {
    const [dashboardData, setDashboardData] = useState<dashboardDataType>();
    useQuery({
        queryKey: ['freelancer-dashboard'],
        queryFn: FreelancerDashboardApi,
        onSuccess: (resp) => {
            setDashboardData(resp?.data);
        },
    });

    return (
        <div className="content-wrapper">

            {/* Header */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Dashboard</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item">Home</li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            {/* content */}
            <section className="content">
                <div className="container-fluid">
                    {/* Small boxes (Stat box) */}
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>{dashboardData?.totalProjects || 0}</h3>
                                    <p>Total Projects</p>
                                </div>
                                <div className="icon">
                                    <FaProjectDiagram />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-success">
                                <div className="inner">
                                <h3>{dashboardData?.completedProjects || 0}</h3>
                                    <p>Completed Projects</p>
                                </div>
                                <div className="icon">
                                <GrCompliance />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* /.container-fluid */}

            </section>
        </div>
    )
};

export default FreelancerDashboard;