import React, { useEffect, useState } from 'react';
import { SelectClient, SelectFreelancer } from './ProjectAdd';
import { toast } from 'sonner';
import { useMutation, useQuery } from 'react-query';
import { ProjectAddSchema } from '../../../Validation';
import { useFormik } from 'formik';
import { ProjectEditApi, ProjectViewApi } from '../../Api';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalLoader } from '../../../Store';
import { projectType } from '../../../Types';

export default function ProjectEdit() {
  const navigate = useNavigate();
  const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
  const [projectData, setProjectData] = useState<projectType>();
  const { id } = useParams();

  const { refetch } = useQuery({
    queryKey: ['project-view', id],
    queryFn: ProjectViewApi,
    onSuccess: (resp) => {
      setProjectData(resp.data.project);
      setIsLoading(false);
    },
    onError: (err: any) => {
      toast.error(err.response.data.message);
      setIsLoading(false);
    },
    enabled: false
  });

  useEffect(() => {
    setIsLoading(true);
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { mutate } = useMutation(ProjectEditApi, {
    mutationKey: 'project-edit',
    onSuccess: (data: any) => {
      toast.success(data.data.message);
      navigate('/admin/project');
      setIsLoading(false);
    },
    onError: (err: any) => {
      toast.error(err.response.data.message);
      setIsLoading(false);
    },
  });

  const { handleSubmit, handleChange, errors, values, touched, setFieldValue } = useFormik({
    initialValues: {
      project_name: projectData?.project_name ? projectData?.project_name : "",
      description: projectData?.description ? projectData?.description : "",
      client_id: projectData?.client_id ? projectData?.client_id : null,
      freelancer_id: projectData?.freelancer_id ? projectData?.freelancer_id : null,
      status: projectData?.status || projectData?.status === 0 ? projectData?.status : 11
    },
    enableReinitialize: true,
    validationSchema: ProjectAddSchema,
    onSubmit: (data) => {
      setIsLoading(true);
      mutate({
        id: projectData?.id,
        project_name: data.project_name,
        description: data.description,
        client_id: data.client_id,
        freelancer_id: data.freelancer_id,
        status: data.status
      });
    },
  });
  return (

    <div className="content-wrapper">

      {/* Back & Heading */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col">
              <h1>Edit Project</h1>
            </div>
            <div className="col">
              <ol className="breadcrumb float-right">
                <li className="breadcrumb-item">
                  <button className="btn btn-info text-white" onClick={() => navigate(-1)}>
                    <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                    Back
                  </button>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="row">

                      {/* Project Name */}
                      <div className="col-lg-6 col-12">
                        <div className="form-group my-1">
                          <label htmlFor="name">Project Name</label>
                          <input type="text" value={values.project_name} onChange={handleChange} className="form-control" id="project_name" placeholder="Please Enter Project Name" />
                        </div>
                        {touched.project_name && errors.project_name ? (<div className="mb-2 mx-1 text-danger">{errors.project_name}</div>) : null}
                      </div>

                      {/* Project Description */}
                      <div className="col-12">
                        <div className="form-group my-1">
                          <label htmlFor="name">Project Description</label>
                          <textarea rows={5} value={values.description} onChange={handleChange} className="form-control textarea" id="description" placeholder="Please Enter Project Description" />
                        </div>
                        {touched.description && errors.description ? (<div className="mb-2 mx-1 text-danger">{errors.description}</div>) : null}
                      </div>

                      {/* Select Client */}
                      <div className="col-lg-6 col-12">
                        <div className="form-group my-1">
                          <label htmlFor="name">Select Client</label>
                          <SelectClient setFieldValue={setFieldValue} value={values.client_id} />
                        </div>
                        {touched.client_id && errors.client_id ? (<div className="mb-2 mx-1 text-danger">{errors.client_id}</div>) : null}
                      </div>

                      {/* Select Project */}
                      <div className="col-lg-6 col-12">
                        <div className="form-group my-1">
                          <label htmlFor="name">Select Project</label>
                          <SelectFreelancer setFieldValue={setFieldValue} value={values.freelancer_id} />
                        </div>
                        {touched.freelancer_id && errors.freelancer_id ? (<div className="mb-2 mx-1 text-danger">{errors.freelancer_id}</div>) : null}
                      </div>

                      {/* Status */}
                      <div className="col-lg-6 col-12">
                        <label htmlFor="status">Status</label>
                        <div className="form-group d-flex">
                          <div className="custom-control custom-radio mr-2">
                            <input className="custom-control-input cursor-pointer" checked={values.status === 1 ? true : false} onChange={(e) => setFieldValue("status", e.target.checked ? 1 : 0)} type="radio" id="customRadio1" name="status" />
                            <label htmlFor="customRadio1" className="custom-control-label cursor-pointer" >Active</label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input className="custom-control-input cursor-pointer" checked={values.status === 0 ? true : false} onChange={(e) => setFieldValue("status", e.target.checked ? 0 : 1)} type="radio" id="customRadio2" name="customRadio1" />
                            <label htmlFor="customRadio2" className="custom-control-label cursor-pointer" >Deactive</label>
                          </div>
                        </div>
                        {touched.status && errors.status ? (<div className="mb-2 mx-1 text-danger">{errors.status}</div>) : null}
                      </div>
                    </div>

                  </div>
                  {/* /.card-body */}
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">Save</button>
                    <button type="button" className="btn btn-secondary mx-2" onClick={() => navigate('/admin/project')}>Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
