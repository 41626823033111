import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { ProjectScreenshotAdd } from '../Freelancer/Api';

function TakeScreenshot() {
    const [isCapturing, setIsCapturing] = useState(false);
    const [screenshotCount, setScreenshotCount] = useState(0);
    const [stream, setStream] = useState<any>();

    const { mutate } = useMutation({
        mutationFn: ProjectScreenshotAdd,
        mutationKey: 'add-screenshot',
    })

    function getDisplayMedia(options: any) {

        if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
            return navigator.mediaDevices.getDisplayMedia(options).then((result) => {
                return result;
            }).catch((err) => {
                console.log(err);
            });;
        }
        throw new Error('getDisplayMedia is not defined');
    }


    async function takeScreenshotStream() {
        const width = window.innerWidth * (window.devicePixelRatio || 1);
        const height = window.innerHeight * (window.devicePixelRatio || 1);

        const mediaStreamConstraints = {
            audio: false,
            video: {
                width,
                height,
                frameRate: 1,
            },
        };

        let newStream;
        try {
            newStream = await getDisplayMedia(mediaStreamConstraints)
            setStream(newStream);
        } catch (err) {
            console.error(err);
        }

        return newStream;
    }

    async function takeScreenshotCanvas(newStream: any) {
        const video: any = document.createElement('video');

        const result = await new Promise((resolve, reject) => {
            video.onloadedmetadata = () => {
                video.play();
                video.pause();

                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                const context: any = canvas.getContext('2d');
                context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

                resolve(canvas);
            };

            video.srcObject = newStream;
        });

        if (result == null) {
            throw new Error('Cannot take canvas screenshot');
        }

        return result;
    }

    const startCapturing = (newStream: any) => {
        const takeTime = Math.floor(Math.random() * (5 - 2 + 1) + 2);
        setTimeout(() => {

            takeScreenshotCanvas(newStream).then((result: any) => {
                console.log(result)
                mutate({
                    id: 1,
                    image: result.toDataURL("image/png")
                })
                setScreenshotCount(screenshotCount + 1);
            }).catch((err) => {
                alert('something wrong please start again capturing.');
                setScreenshotCount(screenshotCount + 1);
            });
        }, takeTime * 1000);
    }

    const handleCapture = async () => {
        if (isCapturing) {
            setStream(null);
            setIsCapturing(false);
            stream.getTracks().forEach(function (track: any) {
                track.stop();
            });
        } else {
            setIsCapturing(true);
            const newStream = await takeScreenshotStream();
            setStream(newStream);
            startCapturing(newStream);
        }
    }

    useEffect(() => {
        if (isCapturing) {
            startCapturing(stream);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenshotCount]);

    return (
        <div className='mx-2'>
            <button type='button' className={isCapturing ? 'btn btn-danger' : 'btn btn-success'} onClick={async () => await handleCapture()}>
                {isCapturing ? 'Stop Capture' : 'Start Capture'}
            </button>
        </div>
    );
}

export default TakeScreenshot;
