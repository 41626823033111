import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Loader from './Components/Loader';
import { Toaster } from 'sonner';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Style/index.css';

export const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Fragment>
    <QueryClientProvider client={queryClient}>
      <Loader />
      <App />
      <Toaster richColors position="top-center" />
    </QueryClientProvider>
  </React.Fragment>
);