import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { BiSolidDashboard } from "react-icons/bi";
import { FaLock, FaProjectDiagram} from "react-icons/fa";

const FreelancerSidebar = [
    {
        name: "Dashboard",
        link: "/freelancer/dashboard",
        icon: <BiSolidDashboard className="nav-icon" />,
        active: "dashboard",
    },
    {
        name: "Projects",
        link: "/freelancer/project",
        icon: <FaProjectDiagram className="nav-icon" />,
        active: "project",
    },
    {
        name: "Change Password",
        link: "/freelancer/change-password",
        icon: <FaLock className="nav-icon" />,
        active: "change-password",
    },
];

function Sidebar() {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('');

    useEffect(() => {
        const path = location.pathname.split('/')[2];
        setActiveTab(path);
    }, [location]);

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/freelancer/dashboard" className="brand-link" style={{ textDecoration: 'none' }}>
                <img src="/dist/img/AdminLTELogo.png" alt="FreelancerLTE Logo" className="brand-image img-circle elevation-3" />
                <span className="brand-text font-weight-light" >Freelancer Panel</span>
            </Link>
            {/* Sidebar */}
            <div className="sidebar">

                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {FreelancerSidebar.map((item, i) => (
                            <li className={`nav-item Active`} key={i}>
                                <Link to={item.link} className={`nav-link  ${activeTab === item.active ? "active" : ""}`}>
                                    {item.icon}
                                    <p>
                                        {item.name}
                                    </p>
                                </Link>
                            </li>
                        ))
                        }
                    </ul>
                </nav>
            </div>
        </aside>
    )
};
export default Sidebar