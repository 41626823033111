import React from 'react';
import { Modal } from 'react-bootstrap';
import { BsCheckCircle, BsInfoCircle } from "react-icons/bs";

interface ModalType {
    show?: boolean,
    successButton?: string,
    cancelButton?: string,
    description?: string,
    header?: string,
    setShow: (show: boolean) => void
    handleSuccess: () => void,
    type: string,
    getInputValue?: (deleteReason: string) => void,
    showInput?: boolean,
    inputValue?: string,
    placeholder?: string,
    showIcon?: boolean,
    inputHelperText?: string
}

export default function CustomModal({
    show,
    successButton,
    cancelButton,
    description,
    header,
    setShow,
    handleSuccess,
    type,
    getInputValue,
    showInput,
    inputValue,
    placeholder,
    showIcon = true,
    inputHelperText

}: ModalType) {
    const handleClose = () => {
        setShow(false);
        if (showInput) {
            getInputValue!("");
        }
    }
    return (
        <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className='d-flex flex-column text-center'>
                <div className='my-2'>
                    {showIcon && type === "success" && <BsCheckCircle className='text-success' style={{ width: '70px', height: '70px' }} />}
                    {showIcon && type === "danger" && <BsInfoCircle className='text-danger' style={{ width: '70px', height: '70px' }} />}
                </div>
                <h2 className='my-2'>{header}</h2>
                {description ? <p className='my-2 h5'>{description}</p> : null}
                {showInput ? (
                    <div>
                        <div className="form-group my-3">
                            <input required={true} type="text" value={inputValue} onChange={(e: any) => getInputValue ? getInputValue(e.target.value.trimStart()) : null} className="form-control" id="name" placeholder={placeholder} />
                            {inputHelperText ? (
                                <div className="mb-1 mx-1 text-danger">
                                    {inputHelperText}
                                </div>
                            ) : null}
                        </div>
                        <div className='my-2'>
                            <button type="button" style={{minWidth:"100px"}} className={`btn btn-${type} mx-2`} onClick={() => handleSuccess()}>{successButton ? successButton : "Yes"}</button>
                            <button type="button" style={{minWidth:"100px"}} className="btn btn-secondary" onClick={handleClose}>{cancelButton ? cancelButton : "Cancel"}</button>
                        </div>
                    </div>
                ) :
                    (
                        <div className='my-2'>
                            <button className={`btn btn-${type} mx-2`} style={{minWidth:"100px"}} onClick={() => handleSuccess()}>{successButton ? successButton : "Yes"}</button>
                            <button className="btn btn-secondary" style={{minWidth:"100px"}} onClick={handleClose}>{cancelButton ? cancelButton : "Cancel"}</button>
                        </div>
                    )
                }
            </Modal.Body>
        </Modal>
    );
}
