import React, { useState } from "react";
import { LoginSchema } from "../../Validation";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from 'sonner';
import { LoginAPI } from "../Api";

export default function FreelancerLogin() {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const { mutate } = useMutation(LoginAPI, {
        mutationKey: "freelancer-login",
        onSuccess: (data: any) => {
            const token = data.data.token;
            localStorage.setItem("freelancer_token", token);
            navigate('/freelancer/dashboard');
            toast.success('Login success');
        },
        onError: (data: any) => {
            toast.error(data.response.data.message)
        }

    });

    const handleShowPassword = () => {
        if (showPassword) {
            setShowPassword(false);
        } else {
            setShowPassword(true);
        }
    };
    const { handleSubmit, handleChange, errors, values, touched } = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: LoginSchema,
        onSubmit: (data) => {
            mutate({
                email: data.email,
                password: data.password
            });
        },
    });

    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                <div className="login-logo">
                </div>
                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">Freelancer Login</p>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <div className="input-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        placeholder="Email"
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope" />
                                        </div>
                                    </div>
                                </div>
                                {touched.email && errors.email ? (
                                    <div className=" mx-1 text-danger">
                                        {errors.email}
                                    </div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <div className="input-group">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className="form-control"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        placeholder="Password"
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span
                                                className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"}
                                                onClick={handleShowPassword}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {touched.password && errors.password ? (
                                    <div className=" mx-1 text-danger">
                                        {errors.password}
                                    </div>
                                ) : null}
                            </div>

                            <div className="row">
                                <div className="col">
                                    <button type="submit" className="btn btn-primary btn-block">
                                        Log In
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
