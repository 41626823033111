import FreelancerChangePassword from "../Freelancer/Pages/ChangePassword";
import FreelancerDashboard from "../Freelancer/Pages/Dashboard";
import FreelancerProjectView from "../Freelancer/Pages/Projects/FreelancerProjectView";
import FreelancerProjects from '../Freelancer/Pages/Projects/FreelancerProjects';

const FreelancerRoute = [
    { path: "dashboard", element: <FreelancerDashboard /> },
    { path: "change-password", element: <FreelancerChangePassword /> },
    { path: "/freelancer/project", element: <FreelancerProjects /> },
    { path: "/freelancer/project/view/:id", element: <FreelancerProjectView/>},
];

export default FreelancerRoute;