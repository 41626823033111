import ClientChangePassword from "../Client/Pages/ChangePassword";
import ClientDashboard from "../Client/Pages/Dashboard";
import ClientProjectView from "../Client/Pages/Projects/ClientProjectView";
import ClientProjects from '../Client/Pages/Projects/ClientProjects';

const ClientRoute = [
    { path: "dashboard", element: <ClientDashboard /> },
    { path: "change-password", element: <ClientChangePassword /> },
    { path: "/client/project", element: <ClientProjects /> },
    { path: "/client/project/view/:id", element: <ClientProjectView/>},
];

export default ClientRoute;