import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminLogin from './Admin/Pages/Login';
import AdminLogged from './Middleware/AdminLogged';
import AdminProtectedRoute from './Middleware/AdminProtectedRoute';
import AdminLayout from './Admin/Layout';
import AdminRoute from './Routes/AdminRoute';
import ClientLogged from './Middleware/ClientLogged';
import ClientLogin from './Client/Pages/Login';
import ClientProtectedRoute from './Middleware/ClientProtectedRoute';
import ClientLayout from './Client/Layout';
import ClientRoute from './Routes/ClientRoute';
import FreelancerLogged from './Middleware/FreelancerLogged';
import FreelancerLogin from './Freelancer/Pages/Login';
import FreelancerProtectedRoute from './Middleware/FreelancerProtectedRoute';
import FreelancerRoute from './Routes/FreelancerRoute';
import FreelancerLayout from './Freelancer/Layout';
import PageNotFound from './Components/PageNotFound';

function App() {
  return (
    <BrowserRouter>
      <Routes>

        {/* Admin Routes */}
        <Route path='/admin/login' element={<AdminLogged><AdminLogin /></AdminLogged>} />
        <Route path="/admin" element={<AdminProtectedRoute />}>
          <Route path="" element={<AdminLayout />}>
            {AdminRoute.map((item, i) => <Route path={item?.path} element={item?.element} key={i} />)}
          </Route>
        </Route>

        {/* Client Routes */}
        <Route path='/client/login' element={<ClientLogged><ClientLogin /></ClientLogged>} />
        <Route path="/client" element={<ClientProtectedRoute />}>
          <Route path="" element={<ClientLayout />}>
            {ClientRoute.map((item, i) => <Route path={item?.path} element={item?.element} key={i} />)}
          </Route>
        </Route>

        {/* Freelancer Routes */}
        <Route path='/freelancer/login' element={<FreelancerLogged><FreelancerLogin /></FreelancerLogged>} />
        <Route path="/freelancer" element={<FreelancerProtectedRoute />}>
          <Route path="" element={<FreelancerLayout />}>
            {FreelancerRoute.map((item, i) => <Route path={item?.path} element={item?.element} key={i} />)}
          </Route>
        </Route>

        {/* 404 Page */}
        <Route path="*" element={<PageNotFound />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
