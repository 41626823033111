import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function PageNotFound() {
    const navigate = useNavigate()
    return (
        <div className='min-vw-100 min-vh-100 d-flex align-items-center justify-content-center flex-column'>
            <h1 className="headline text-warning"> 404</h1>
            <h3><i className="fas fa-exclamation-triangle text-warning" /> Oops! Page not found.</h3>
            <button
                className="btn btn-info"
                onClick={() => navigate(-1)}
            >
                <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                Back
            </button>
        </div>
    );
}