import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalLoader } from '../../../Store';
import { useMutation, useQuery } from 'react-query';
import { ClientEditApi, ClientViewApi } from '../../Api';
import { toast } from 'sonner';
import { useFormik } from 'formik';
import { ClientEditSchema } from '../../../Validation';
import { clientType } from '../../../Types';

export default function ClientEdit() {
  const navigate = useNavigate();
  const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
  const [clientData, setClientData] = useState<clientType>();
  const { id } = useParams();

  const { refetch } = useQuery({
    queryKey: ['client-view', id],
    queryFn: ClientViewApi,
    onSuccess: (resp) => {
      setClientData(resp.data.client);
      setIsLoading(false);
    },
    onError: (err: any) => {
      toast.error(err.response.data.message);
      setIsLoading(false);
    },
    enabled: false
  });

  useEffect(() => {
    setIsLoading(true);
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { mutate } = useMutation(ClientEditApi, {
    mutationKey: 'client-edit',
    onSuccess: (data: any) => {
      toast.success(data.data.message);
      navigate('/admin/client');
      setIsLoading(false);
    },
    onError: (err: any) => {
      toast.error(err.response.data.message);
      setIsLoading(false);
    },
  });

  const { handleSubmit, handleChange, errors, values, touched, setFieldValue } = useFormik({
    initialValues: {
      name: clientData?.name ? clientData?.name : "",
      email: clientData?.email ? clientData?.email : "",
      status: clientData?.status || clientData?.status === 0 ? clientData?.status : 1,
    },
    enableReinitialize: true,
    validationSchema: ClientEditSchema,
    onSubmit: (data) => {
      setIsLoading(true);
      mutate({
        name: data.name,
        email: data.email,
        status: data.status,
        id: clientData?.id
      });
    },
  });

  return (
    <div className="content-wrapper">

      {/* Back & Heading */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col">
              <h1>Edit Client</h1>
            </div>
            <div className="col">
              <ol className="breadcrumb float-right">
                <li className="breadcrumb-item">
                  <button className="btn btn-info text-white" onClick={() => navigate(-1)}>
                    <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                    Back
                  </button>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="row">

                      {/* Name */}
                      <div className="col-lg-6 col-12">
                        <div className="form-group my-1">
                          <label htmlFor="name">Name</label>
                          <input type="text" value={values.name} onChange={handleChange} className="form-control" id="name" placeholder="Please Enter Name" />
                        </div>
                        {touched.name && errors.name ? (<div className="mb-2 mx-1 text-danger">{errors.name}</div>) : null}
                      </div>

                      {/* E-mail */}
                      <div className="col-lg-6 col-12">
                        <div className="form-group my-1">
                          <label htmlFor="name">E-mail</label>
                          <input type="text" value={values.email} onChange={handleChange} className="form-control" id="email" placeholder="Please Enter E-mail" />
                        </div>
                        {touched.email && errors.email ? (<div className="mb-2 mx-1 text-danger">{errors.email}</div>) : null}
                      </div>

                      {/* Status */}
                      <div className="col-lg-6 col-12">
                        <label htmlFor="status">Status</label>
                        <div className="form-group d-flex">
                          <div className="custom-control custom-radio mr-2">
                            <input className="custom-control-input cursor-pointer" checked={values.status === 1 ? true : false} onChange={(e) => setFieldValue("status", e.target.checked ? 1 : 0)} type="radio" id="customRadio1" name="status" />
                            <label htmlFor="customRadio1" className="custom-control-label cursor-pointer" >Active</label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input className="custom-control-input cursor-pointer" checked={values.status === 0 ? true : false} onChange={(e) => setFieldValue("status", e.target.checked ? 0 : 1)} type="radio" id="customRadio2" name="customRadio1" />
                            <label htmlFor="customRadio2" className="custom-control-label cursor-pointer" >Deactive</label>
                          </div>
                        </div>
                        {touched.status && errors.status ? (<div className="mb-2 mx-1 text-danger">{errors.status}</div>) : null}
                      </div>
                    </div>

                  </div>
                  {/* /.card-body */}
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">Save</button>
                    <button type="button" className="btn btn-secondary mx-2" onClick={() => navigate('/admin/client')}>Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
