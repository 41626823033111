import React, { useState } from 'react';
import { toast } from 'sonner';
import { useGlobalLoader } from '../../../Store';
import { ProjectViewApi, ViewProjectScreenshot } from '../../Api';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { projectType } from '../../../Types';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ChatBox from '../../../Components/ChatBox';
import ImageModal from '../../../Components/ImageModal';

export default function AdminProjectView() {
    const { id } = useParams();
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
    const navigate = useNavigate();
    const [projectData, setProjectData] = useState<projectType>();
    const [screenshot, setScreenshot] = useState<{ image: string }[]>([]);
    const [limit, setLimit] = useState(30);
    const [showImage, setShowImage] = useState<boolean>(false);
    const [image, setImage] = useState('');
    const [totalScreenshot, setTotalScreenshot] = useState(0);

    useQuery({
        queryKey: ['project-view', id],
        queryFn: ProjectViewApi,
        onSuccess: (resp) => {
            setProjectData(resp?.data?.project);
            setIsLoading(false);
        },
        onError: (err: any) => {
            setIsLoading(false);
            toast.error(err.response.data.message);
        }
    });

    const { isFetching } = useQuery({
        queryKey: ['screenshot-view', id, limit],
        queryFn: ViewProjectScreenshot,
        onSuccess: (resp) => {
            setScreenshot(resp?.data?.screenshot);
            setTotalScreenshot(resp?.data?.totalScreenshot);
        },
        onError: (err: any) => {
            toast.error(err.response.data.message);
        }
    });

    return (
        <div className="content-wrapper">
            <ImageModal show={showImage} setShow={setShowImage} image={image} />
            {/* Back & Heading */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col">
                            <h1>View Project</h1>
                        </div>
                        <div className="col">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item">
                                    <button className="btn btn-info text-white" onClick={() => navigate(-1)}>
                                        <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                                        Back
                                    </button>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            {/* Main Content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <Tabs defaultActiveKey="Details" id="justify-tab-example" className="mb-3 tabs" justify>
                                        <Tab eventKey="Details" title="Details">
                                            <div className="table-responsive mt-4">
                                                <div className="card-body">
                                                    <table id="example2" className="table table-bordered">
                                                        {projectData ?
                                                            <tbody>
                                                                <tr>
                                                                    <td className="col-sm-2 font-weight-bold">Project Name</td>
                                                                    <td>{projectData?.project_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="col-sm-2 font-weight-bold">Description</td>
                                                                    <td>{projectData.description}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="col-sm-2 font-weight-bold">Status</td>
                                                                    <td>
                                                                        {projectData.status === 0 && "Deactive"}
                                                                        {projectData.status === 1 && "Active"}
                                                                        {projectData.status === 2 && "Completed"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="col-sm-2 font-weight-bold">Clients</td>
                                                                    <td>
                                                                        <p className='mb-0 h6'>{projectData?.client?.name}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="col-sm-2 font-weight-bold">Freelancers</td>
                                                                    <td>
                                                                    <p className='mb-0 h6'>{projectData?.freelancer?.name}</p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            : null
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Chat" title="Chat">
                                            <ChatBox project_id={Number(id)} chatType='admin'/>
                                        </Tab>
                                        <Tab eventKey="Screenshot" title="Screenshot">
                                            <div className='d-flex flex-wrap'>
                                                {screenshot ? screenshot.map((item, i) => (<div key={i} className='m-2' style={{ width: 150, height: 150 }}>
                                                    <img src={item?.image} alt='' className='w-100 h-100 cursor-pointer' onClick={() => { setImage(item?.image); setShowImage(true) }} />
                                                </div>)) : null}
                                            </div>
                                            <div className='d-flex w-100 justify-content-center align-items-center'>
                                                {isFetching ? <center><i className=" my-2 fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "black" }}></i></center>
                                                    : <button hidden={screenshot.length >= totalScreenshot} className='btn btn-primary' onClick={() => setLimit(limit + 10)}>Loadmore</button>}
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}
