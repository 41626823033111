import React  from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";

function Home() {
    return (
        <div className="wrapper">
            <Navbar />
            <Sidebar />
            <Outlet />
            <Footer />
        </div>
    )
};

export default Home;