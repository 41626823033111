import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'sonner';
import { useFormik } from 'formik';
import { ChangePasswordSchema } from '../../Validation';
import { ChangePasswordApi } from '../Api';

export default function ClientChangePassword() {

    const [showPassword, setShowPassword] = useState<{
        old_password: boolean,
        new_password: boolean,
        confirm_password: boolean
    }>({
        old_password: false,
        new_password: false,
        confirm_password: false
    })
    const { mutate } = useMutation(ChangePasswordApi, {
        mutationKey: "client-password-change",
        onSuccess: (data: any) => {
            toast.success(data.data.message);
            resetForm();
        },
        onError: (data: any) => {
            toast.error(data.response.data.message);
        }
    });
    const { handleSubmit, handleChange, errors, values, touched, resetForm } = useFormik({
        initialValues: {
            old_password: "",
            new_password: "",
            confirm_password: "",
        },
        validationSchema: ChangePasswordSchema,
        onSubmit: (data) => {
            mutate({
                old_password: data.old_password,
                new_password: data.new_password,
                confirm_password: data.confirm_password,
            });
        },
    });
    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Change Password</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">

                            <div className="card">
                                <form onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12 col-12">
                                                <div className="col-lg-6 col-12">
                                                    <div className="form-group my-1">
                                                        <label htmlFor="old_password">Old Password</label>
                                                        <div className="input-group">
                                                            <input
                                                                type={showPassword.old_password ? "text" : "password"}
                                                                className="form-control"
                                                                name="old_password"
                                                                id="old_password"
                                                                value={values.old_password}
                                                                onChange={handleChange}
                                                                placeholder="Old password"
                                                            />
                                                            <div className="input-group-append">
                                                                <div className="input-group-text" onClick={() => { setShowPassword({ ...showPassword, old_password: showPassword.old_password ? false : true }) }}>
                                                                    <span
                                                                        className={showPassword.old_password ? "fas fa-eye cursor-pointer" : "fas fa-eye-slash cursor-pointer"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {touched.old_password && errors.old_password ? (
                                                        <div className="mb-2 mx-1 text-danger">{errors.old_password}</div>
                                                    ) : null}

                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="form-group my-1">
                                                        <label htmlFor="new_password">New Password</label>

                                                        <div className="input-group">
                                                            <input
                                                                type={showPassword.new_password ? "text" : "password"}
                                                                className="form-control"
                                                                name="new_password"
                                                                id="new_password"
                                                                value={values.new_password}
                                                                onChange={handleChange}
                                                                placeholder="New password"
                                                            />
                                                            <div className="input-group-append" onClick={() => { setShowPassword({ ...showPassword, new_password: showPassword.new_password ? false : true }) }}>
                                                                <div className="input-group-text">
                                                                    <span
                                                                        className={showPassword.new_password ? "fas fa-eye cursor-pointer" : "fas fa-eye-slash cursor-pointer"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {touched.new_password && errors.new_password ? (
                                                        <div className="mb-2 mx-1 text-danger">{errors.new_password}</div>
                                                    ) : null}

                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="form-group my-1">
                                                        <label htmlFor="confirm_password">Confirm Password</label>

                                                        <div className="input-group">
                                                            <input
                                                                id="confirm_password"
                                                                type={showPassword.confirm_password ? "text" : "password"}
                                                                className="form-control"
                                                                name="confirm_password"
                                                                value={values.confirm_password}
                                                                onChange={handleChange}
                                                                placeholder="Confirm password"
                                                            />
                                                            <div className="input-group-append">
                                                                <div className="input-group-text" onClick={() => { setShowPassword({ ...showPassword, confirm_password: showPassword.confirm_password ? false : true }) }}>
                                                                    <span
                                                                        className={showPassword.confirm_password ? "fas fa-eye cursor-pointer" : "fas fa-eye-slash cursor-pointer"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {touched.confirm_password && errors.confirm_password ? (
                                                        <div className="mb-2 mx-1 text-danger">{errors.confirm_password}</div>
                                                    ) : null}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary">Change Password</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}
