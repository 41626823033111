import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ClientLogged(props: any) {
    const navigate = useNavigate();
    const [checkLoggedIn, setCheckLoggedIn] = useState(false);

    const checkToken = () => {
        const token = localStorage.getItem('client_token');
        if (token) {
            setCheckLoggedIn(false);
            return navigate('/client/dashboard');
        } else setCheckLoggedIn(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => checkToken(), []);
    return (
        <>
            {
                checkLoggedIn ? props.children : null
            }

        </>
    );
}
