import React from "react";
import { useNavigate } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { BiSolidUserCircle } from "react-icons/bi";
import { Dropdown } from "react-bootstrap";

function Navbar() {
    const navigate = useNavigate();
    const handleClientLogout = () => {
        localStorage.removeItem('client_token');
        navigate('/client/login');
    }

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="/" role="button"><i className="fas fa-bars" /></a>
                </li>
            </ul>

            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                <li className="nav-item mx-2">
                    <Dropdown>
                        <Dropdown.Toggle variant="light" style={{ backgroundColor: 'transparent', border: 'none', margin: 0, padding: 0 }}>
                            <BiSolidUserCircle size={30} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleClientLogout}>
                                <BiLogOut size={25} /><span className="mx-2">Logout</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
            </ul>
        </nav>
    )
}

export default Navbar;